<!-- 学分统计 -->
<template>
    <div class="scoreBox">
        <div class="myTabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="userInfo.currentProject.name" name="study">
                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- 查询类型 -->
        <el-radio-group v-model="searchType" style="margin-bottom: 20px;margin-left:20px">
            <el-radio-button label="tea">按教师查询</el-radio-button>
            <el-radio-button label="phase">按学段查询</el-radio-button>
        </el-radio-group>
        <!-- 查询条件 -->
        <div class="searchBox flex-align">
            <el-date-picker class="time" v-model="value" size="mini" type="datetimerange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
            <div class="phase" v-if="searchType=='phase'">
                学段:
                <el-select v-model="phaseValue" placeholder="请选择" size="mini">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-input placeholder="请输入内容" class="myInput" size="mini" prefix-icon="el-icon-search" v-model="input">
            </el-input>
        </div>
        <!-- 导出 -->
        <div class="export">导出</div>
        <!-- 表格 -->
        <div class="mid-bot">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column align="center" prop="num" label="序号"></el-table-column>
                <el-table-column align="center" prop="teaName" label="教师姓名"></el-table-column>
                <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
                <el-table-column align="center" prop="address" label="地区"></el-table-column>
                <el-table-column align="center" prop="school" label="学校"></el-table-column>
                <el-table-column align="center" prop="score" label="学分"></el-table-column>
                <el-table-column align="center" prop="type" label="操作" fixed="right">
                    <template>
                        <el-button type="text" size="small" style="margin-left:10px" @click="checkScore">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="myPages flex-align-around">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-sizes="[8, 10, 20]" :page-size="8"
                layout=" prev, pager, next,sizes" :total="total" background>
            </el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                searchType: 'tea',
                activeName: 'study',
                tableData: [
                //     {
                //     num: 1,
                //     teaName: '王秀远',
                //     phone: '12312312312',
                //     address: '新疆/和田地区/墨玉县',
                //     school: '实验中学',
                //     score: 155
                // }
                ],
                currentPage: 1,
                userInfo: this.$store.state.user,
                value: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }],
                phaseValue: '',
                input:'',
                total:0
            }
        },
        watch:{
            searchType(){
                console.log('查询类型改变');
                this.input = '';
                this.phaseValue = '';
                this.value = ''
            }
        },
        methods: {
            handleClick() {
                // this.$Api.Score.
            },
            handleSizeChange() {
                console.log('每页个数--')
            },
            handleCurrentChange() {
                console.log('tiaoye')
            },
            checkScore(){
                this.$router.push('/checkScore')
            }
        },
        mounted() {},
    };
</script>
<style lang='less' scoped>
    .scoreBox {
        .searchBox {
            margin-left: 20px;
            .time{
                margin-right: 10px;
            }
            .myInput{
                width:200px;
                margin-left: 10px;
            }
        }
        .export{
            display: flex;
            cursor: pointer;
            font-size: 14px;
            color: #409EFF;
            justify-content: flex-end;
            margin-right: 20px;
        }

        .myPages {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
</style>